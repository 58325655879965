<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CustomersTable
          :items="customers"
          hover
          striped
          border
          small
          fixed
          caption="Clientes"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CustomersTable from '../components/customers/CustomersTable.vue'
import customers from '../services/customers';
import store from '../store'

export default {
  name: 'Customers',
  components: { CustomersTable },
  data: function () {
		return {
            customers: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await customers.get(); 

    if(response.type == "success"){
      this.customers = response.data;
    }
    this.loaded();
  },
  methods: {
    async refreshItems (){
      this.loading();
      let response = await customers.get(); 

      if(response.type == "success"){
        this.customers = response.data;
      }
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
