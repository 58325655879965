<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="importCustomerModal"
    >   
      <CForm>
        <CRow>
          <CCol md="12">
            <form id="document_form" method="POST" enctype="multipart/form-data">
              <CInput
                label="Subir archivo:"
                :isValid="checkIfValid('document')"
                :lazy="false"
                placeholder=""
                type="file"
                :value.sync="$v.form.document.$model"
                name="document"
                id="document"
                invalidFeedback="Este campo es requerido."
                autocomplete="document"
              />
            </form>
          </CCol>
        </CRow>
      </CForm>
      <template #footer style="float: left">
        <CButton @click="closeModal" color="secondary">Cancelar</CButton>
        <CButton  @click="downloadTemplate" color="success">Descargar Plantilla</CButton>
        <CButton @click="store" :color="color">Aceptar</CButton>
      </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"
import customers_ws from '../../services/customers';
import store from '../../store'

export default {
  name: 'ImportCustomerModal',
  props: {

  },
  mounted: async function() {
  },
  data () {
    return {
      importCustomerModal: false,
      title: "Importar Cliente",
      color: "info",
      form: this.getEmptyForm(),
      submitted: false,
    }
  },
  methods: {
    importExcelModal () {
      this.importCustomerModal = true;
      this.color = "info";
      this.title = 'Importar Cliente';
      this.submitted = false;

      this.form = this.getEmptyForm();
    },
    async store () {
      this.$v.$touch();
      this.loading();
      if (this.isValid) {
        this.submitted = true;        
        let form = document.getElementById("document_form");
        let formData = new FormData(form);

        let response = await customers_ws.import(formData);

        if(response.type == "success"){
          this.$emit("refresh");
        }        
        this.showToast(response.type, response.message);
      }
      this.loaded();        
      this.closeModal();
    },
    closeModal () {
      this.importCustomerModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          document: "",
      }
    },
    async downloadTemplate () {
      await customers_ws.getTemplate(); 

    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }    
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      document: {
        required,
      },
    }
  },
}
</script>